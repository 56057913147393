export default [
    {
        path: "/",
        text: "Home"
    },
    {
        path: "/page-2/",
        text: "Second"
    },
    {
        path: "/datapage/",
        text: "Data"
    },
    {
        path: "/imagetest",
        text: "Image"
    },
    {
        path: "/formpage/",
        text: "Contact"
    },
    {
        path: "/blog/",
        text: "Posts"
    }
]